<svg class="mb-3" width="50px" id="Camada_2" data-name="Camada 2" xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 976.36 976.36">
  <defs>
    <style>
      .cls-1 {
        fill: #d1fadf;
      }

      .cls-1,
      .cls-2,
      .cls-3 {
        stroke-width: 0px;
      }

      .cls-2 {
        fill: #ecfdf3;
      }

      .cls-3 {
        fill: #039855;
      }
    </style>
  </defs>
  <g id="Camada_1-2" data-name="Camada 1">
    <g>
      <circle class="cls-2" cx="488.18" cy="488.18" r="488.18" />
      <circle class="cls-1" cx="488.18" cy="488.18" r="348.88" />
      <g>
        <path class="cls-3"
          d="m318.67,392.07l62.85-62.54s4.87-2.67,9.27-1.1,19.8,19.33,19.8,19.33c0,0,3.14,6.29,0,9.9s-16.03,16.03-16.03,16.03l193.11.47s19.33,0,34.88,10.53,24.51,20.11,31.43,37.87,3.77,31.9,3.77,31.9c0,0-5.19,9.74-13.67,9.9s-15.24,0-15.24,0c0,0-11.78-2.2-12.41-14.77-.63-12.57-5.66-22.63-15.4-28.75-9.74-6.13-15.56-4.24-15.56-4.24l-191.38-.31,14.46,14.46s6.29,6.91,1.41,12.41-16.81,17.28-16.81,17.28c0,0-6.29,4.24-11.16.31-4.87-3.93-63.32-62.54-63.32-62.54,0,0-1.73-2.67,0-6.13Z" />
        <path class="cls-3"
          d="m657.7,589.54l-62.85,62.54s-4.87,2.67-9.27,1.1c-4.4-1.57-19.8-19.33-19.8-19.33,0,0-3.14-6.29,0-9.9,3.14-3.61,16.03-16.03,16.03-16.03l-193.11-.47s-19.33,0-34.88-10.53c-15.56-10.53-24.51-20.11-31.43-37.87-6.91-17.76-3.77-31.9-3.77-31.9,0,0,5.19-9.74,13.67-9.9,8.49-.16,15.24,0,15.24,0,0,0,11.78,2.2,12.41,14.77.63,12.57,5.66,22.63,15.4,28.75s15.56,4.24,15.56,4.24l191.38.31-14.46-14.46s-6.29-6.91-1.41-12.41,16.81-17.28,16.81-17.28c0,0,6.29-4.24,11.16-.31,4.87,3.93,63.32,62.54,63.32,62.54,0,0,1.73,2.67,0,6.13Z" />
        <path class="cls-3"
          d="m491.32,549.83c-16.33,0-32.66,0-49-.02-1.31,0-2.66-.07-3.92-.37-5.88-1.41-8.56-6.62-6.45-12.44,1.2-3.3,3.47-5.88,5.85-8.35,6.32-6.57,14.07-10.86,22.61-13.72,21.91-7.35,43.74-7.04,65.35,1.25,7.99,3.06,15.16,7.5,20.74,14.11,1.68,1.99,3.3,4.2,4.27,6.58,2.69,6.57-1.36,12.57-8.47,12.92-.7.04-1.4.03-2.1.03-16.29,0-32.58,0-48.87,0Z" />
        <path class="cls-3"
          d="m522.82,465.29c.26,5.05.03,10.03-1.68,14.88-4.86,13.81-19.11,22.62-33.6,20.68-14.05-1.88-25-11.99-27.09-26.08-1.2-8.07-1.48-16.38,1.28-24.35,4.64-13.38,17.97-21.97,32.23-20.86,14.1,1.09,25.94,11.76,28.43,25.63.17.93.39,1.87.41,2.81.06,2.43.02,4.86.02,7.3Z" />
      </g>
    </g>
  </g>
</svg>

<h1 class="title-modal">Transferir Atendimento</h1>
<h6 class="text-zinc-400 font-light text-sm my-2 mt-2">Selecione o departamento e o usuário para transferir o
  atendimento</h6>
<div class="assigned-main">
  <div class="select-container">
    <form [formGroup]="formGroup">

      <label class="font-semibold text-black mb-1">Selecione um departamento</label>
      <mat-select placeholder="Selecione" class="input-field" formControlName="department">
        @for (item of departments; track $index) {
        <mat-option [value]="item._id">{{ item.name }}</mat-option>
        }
      </mat-select>

      <label class="font-semibold text-black mt-4 mb-1">Selecione um atendente</label>
      <mat-select placeholder="Nenhum" class="input-field" formControlName="user">
        <mat-option>Nenhum</mat-option>
        @for (user of users; track $index) {
        <mat-option [value]="user._id">{{ user.name }}</mat-option>
        }
      </mat-select>

    </form>
  </div>
</div>

<div class="w-full flex items-center justify-center gap-2 mt-[15px] ">
  <button
    class="h-[45px] px-14 flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[50%]"
    [matDialogClose]="false">Cancelar</button>
  <button [disabled]="loading"
    class="h-[45px] px-14 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[50%]"
    (click)="confirmTransfer()">
    {{ loading ? '' : 'Confirmar' }}
    <mat-spinner class="mr-4" [diameter]="25" [color]="'accent'" mode="indeterminate" *ngIf="loading"></mat-spinner>
  </button>
</div>