<div class=" rounded-lg p-1 flex flex-col ml-[-5px] cursor-pointer" (click)="openContacts()">
  <div class="flex flex-col bg-[#eee] shadow-global rounded-lg pr-4 pl-4 pb-2 pt-2">
    <div class="flex gap-2">
      <img class="w-8 h-8 rounded-full" [src]="getProfile(message?.vcardContact)"
        alt="Imagem default do contato">
      <p class="font-semibold">{{getNameFormatted(message?.vcardContact)}}</p>
    </div>
    <hr class="mt-[15px] bg-[#bdbdbd]" />
    <small class="text-[#737373]">{{getPhoneFormatted(message?.vcardContact)}}</small>
    <small style="color: #aaabac !important" class="flex items-end justify-end text-[10px] pt-1"><span
        class="timestamp">{{message.updatedAt | date : 'dd/MM/yyyy HH:mm'}}</span></small>
  </div>
</div>