
import { AfterViewInit, Component, ElementRef, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Fancybox } from '@fancyapps/ui';
import { ButtonsComponentObject, HeaderComponentObject, PhoneNumberButtonsComponentObject, TemplateMessageObject, URLButtonsComponentObject } from '@trendbuild/trend-cloud-api';
import { AbstractComponent, Attendance, Message, MessageService, MessageTypeEnum, Pager, UtilHelper, WaTemplateResponse } from 'lib-trend-core';

@Component({
  selector: 'chat-messages-component',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss']
})
export class ChatMessageComponent extends AbstractComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  selectedTemplate: Partial<WaTemplateResponse>;

  @ViewChild('messageContainer') messageContainer: ElementRef<HTMLDivElement>;

  @Input() attendance: Attendance;
  @Input() messages: Array<Message>;
  @Input() pager: Pager<Message>;
  @Output('messageLoadPage') messageLoadPage: EventEmitter<Pager<Message>> = new EventEmitter<Pager<Message>>();
  @Output('messageReplyToAttendance') messageReplyToAttendance: EventEmitter<Message> = new EventEmitter<Message>();
  @Output('messageEditToAttendance') messageEditToAttendance: EventEmitter<Message> = new EventEmitter<Message>();

  constructor(
    injector: Injector,
    protected messageService: MessageService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.initComponent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['attendance'] && changes['attendance'].currentValue) {
      this.attendance = changes['attendance'].currentValue;
    }
    if (changes['messages'] && changes['messages'].currentValue) {
      const container = this.messageContainer?.nativeElement;

      if (container) {
        const currentScrollOffset = container.scrollHeight - container.scrollTop;

        this.messages = changes['messages'].currentValue;
        setTimeout(() => {
          container.scrollTop = container.scrollHeight - currentScrollOffset;
        }, 0);
      } else {
        this.messages = changes['messages'].currentValue;
      }
    }
  }

  ngAfterViewInit(): void {
    this.scrollToBottom();
  }

  override ngOnDestroy() {
    if (this.messageContainer && this.messageContainer.nativeElement) {
      Fancybox.unbind(this.messageContainer.nativeElement);
    }
    Fancybox.close();

    super.ngOnDestroy();
  }

  initComponent() {
    Fancybox.bind('[data-fancybox]', {
      Thumbs: true,
      hideScrollbar: false,
      Carousel: {
        transition: 'slide',
      },
      Images: {
        zoom: true,
      },
    });
  }

  getTemplateButtons(message: Message): Array<PhoneNumberButtonsComponentObject | URLButtonsComponentObject> {
    const template = message.metadata.template as TemplateMessageObject;
    if (template && template.components) {
      const buttonsComponent = template.components.find(component => component.type === 'BUTTONS') as ButtonsComponentObject;
      return buttonsComponent ? buttonsComponent.buttons : [];
    }

    return [];
  }

  scrollToBottom(): void {
    try {
      setTimeout(() => {
        if (this.messageContainer) {
          this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
        }
      }, 100);
    } catch (err) {
      console.error(err);
    }
  }


  isTemplateMidia(message: Message) {
    const template = <TemplateMessageObject>message.metadata.template;
    const headerComponent = <HeaderComponentObject>template?.components.find(c => c.type === 'HEADER');
    return (!!headerComponent && headerComponent!.format !== 'TEXT') ?? false;
  }

  isTemplateImage(message: Message): boolean {
    const template = <TemplateMessageObject>message.metadata.template;
    const headerComponent = <HeaderComponentObject>template?.components.find(c => c.type === 'HEADER');
    return headerComponent?.format === 'IMAGE';
  }

  isTemplateDocument(message: Message): boolean {
    const template = <TemplateMessageObject>message.metadata.template;
    const headerComponent = <HeaderComponentObject>template?.components.find(c => c.type === 'HEADER');
    return headerComponent?.format === 'DOCUMENT';
  }

  isTemplateVideo(message: Message): boolean {
    const template = <TemplateMessageObject>message.metadata.template;
    const headerComponent = <HeaderComponentObject>template?.components.find(c => c.type === 'HEADER');
    return headerComponent?.format === 'VIDEO';
  }

  formatWhatsappMessage(text: string): string {
    return UtilHelper.formatWhatsappMessage(text);
  }

  getContentTemplateMessage(message: Message): string {
    const template = message.metadata.template as TemplateMessageObject;
    const headerComponent = template?.components.find(c => c.type === 'BODY') as HeaderComponentObject;

    if (!headerComponent || !headerComponent.text) {
      return '';
    }

    let text = headerComponent.text;
    const params = message.metadata.params ?? message.metadata.bodyParams;

    if (params && Array.isArray(params)) {
      params.forEach((param, index) => {
        const placeholder = `{{${index + 1}}}`;
        text = text.replace(placeholder, param);
      });
    }

    return text;
  }

  getTypeAndCaption(message: Message): { isCaption: boolean, type: string } {
    const mimeType = this.helperExtension(message.content);
    return { isCaption: message.caption ? true : false, type: mimeType }
  }

  private helperExtension(midia: string): 'image' | 'location' | 'video' | 'document' {
    const decodedUrl = decodeURIComponent(midia);

    const fileNameWithQuery = decodedUrl.split('/').pop() || '';
    const fileName = fileNameWithQuery.split('?')[0];

    const extension = fileName.split('.').pop()?.toLowerCase();

    const regex = /_(\d+)/;
    const nameWithoutPart = extension.replace(regex, '');

    const mimeType = UtilHelper.getMimeType(nameWithoutPart);

    switch (mimeType) {
      case 'video':
        return 'video';
      case 'image':
        return 'image';
      default:
        return 'document'
    }
  }

  scrollToMessage(event: MouseEvent, messageId: string) {
    event.preventDefault();
    const element = document.getElementById(messageId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  isMedia(type: string): boolean {
    return type === MessageTypeEnum.audio || type === MessageTypeEnum.image || type === MessageTypeEnum.video;
  }

  getImageDefault(event: Event): void {
    UtilHelper.onStickerError(event);
  }

  getMessageToSendAttendance(message: Message): void {
    this.messageReplyToAttendance.emit(message);
  }

  getMessageEditToSendAttendance(message: Message): void {
    this.messageEditToAttendance.emit(message);
  }

  loadPage(): void {
    this.messageLoadPage.emit();
  }
}
